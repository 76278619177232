let baseSize = 16
function setRem() {
    const salepro = document.documentElement.clientWidth / 750
    // 当前页面宽度相对于 750 宽的缩放比例，可根据自己需要修改.
    if (window.innerWidth <= 768) {
        baseSize = 40
    } else {
        baseSize = 16
    }
    // console.log('baseSize', baseSize)
    // 设置页面根节点字体大小
    document.documentElement.style.fontSize = (baseSize * Math.min(salepro, 2)) + 'px'
}
setRem()
// 改变窗口大小时重新设置 rem
window.onresize = function () {
    setRem()
}
